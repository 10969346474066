import React from "react";
import { RlaType, RrfaType } from "../../../shared/types";
import dayjs from "dayjs";
import classes from "./styles.module.scss";
import TableColumn from "../TableColumn";
import DataInfo from "../../DataInfo";
import Status from "../../Status";

type TableRowProps = {
    rrfaRow: RrfaType | null;
    rlaRow: RlaType | null;
};

const TableRow = ({ rrfaRow, rlaRow }: TableRowProps): JSX.Element | null => {
    return rrfaRow ? (
        <tr className={classes.TableRow}>
            <TableColumn>
                <DataInfo
                    overallText={rrfaRow.NumeroIscrizione}
                    title={rrfaRow.RagioneSociale}
                    description={rrfaRow.IdentificativoFiscale}
                    isSospeso={rrfaRow.IsSospeso}
                />
            </TableColumn>
            <TableColumn dataColumn="sede">
                <DataInfo title={rrfaRow.Citta} description={rrfaRow.Indirizzo} />
            </TableColumn>
            <TableColumn dataColumn="direttore">
                <DataInfo title={rrfaRow.DirettoreCorsi} />
            </TableColumn>
            <TableColumn dataColumn="FAD Asincrona">
                <Status value={rrfaRow.FormazioneADistanzaAsync} />
            </TableColumn>
            <TableColumn dataColumn="FAD Sincrona">
                <Status value={rrfaRow.FormazioneADistanzaSync} />
            </TableColumn>
            <TableColumn dataColumn="Form. residenziale">
                <Status value={rrfaRow.FormazioneResidenziale} />
            </TableColumn>
            <TableColumn dataColumn="decreti">
                {rrfaRow.Decreti &&
                    rrfaRow.Decreti.length > 0 &&
                    rrfaRow.Decreti.map((dec, index) => {
                        return (
                            <DataInfo
                                key={index}
                                link={
                                    dec.Url
                                        ? {
                                            url: dec.Url,
                                            title: `Visualizza decreto N° ${dec.Numero}`,
                                        }
                                        : undefined
                                }
                                title={`Decreto N° ${dec.Numero}`}
                                description={`del ${dayjs(dec.Data).format("DD/MM/YYYY")}`}
                            />
                        );
                    })}
            </TableColumn>
            <TableColumn dataColumn="sedi">
                {rrfaRow.Sedi &&
                    rrfaRow.Sedi.length > 0 &&
                    rrfaRow.Sedi.map((sede, index) => {
                        return (
                            <DataInfo
                                key={index}
                                title={sede.Indirizzo}
                                description={sede.Citta}
                            />
                        );
                    })}
            </TableColumn>
        </tr>
    ) : rlaRow ? (
        <tr className={classes.TableRow}>
            <TableColumn>
                <DataInfo
                    title={rlaRow.RagioneSociale}
                    description={rlaRow.IdentificativoFiscale}
                />
            </TableColumn>
            <TableColumn dataColumn="sede">
                <DataInfo title={rlaRow.Citta} description={rlaRow.Indirizzo} />
            </TableColumn>
            <TableColumn dataColumn="N° di iscrizione">
                <DataInfo title={rlaRow.NumeroIscrizione} />
            </TableColumn>
            <TableColumn dataColumn="decreti">
                {rlaRow.Decreti && rlaRow.Decreti.length > 0 ? (
                    rlaRow.Decreti.map((dec, index) => {
                        return (
                            <DataInfo
                                key={index}
                                link={
                                    dec.Url
                                        ? {
                                            url: dec.Url,
                                            title: `Visualizza decreto N° ${dec.Numero}`,
                                        }
                                        : undefined
                                }
                                title={`Decreto N° ${dec.Numero}`}
                                description={`del ${dayjs(dec.Data).format("DD/MM/YYYY")}`}
                            />
                        );
                    })
                ) : (
                    <span className={classes["TableRow-empty"]}>Assenti</span>
                )}
            </TableColumn>
        </tr>
    ) : null;
};

export default TableRow;
