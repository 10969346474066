import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import * as routes from "../../shared/routes";

// Routes
import Rrfa from "../../routes/Rrfa";
import Home from "../../routes/Home";
import Rla from "../../routes/Rla";

const RouterWrapper = (): JSX.Element => {
  return (
    <Switch>
      <Route exact={true} path={routes.HOMEPAGE} component={Home} />
      <Route exact={true} path={routes.RRFA} component={Rrfa} />
      <Route exact={true} path={routes.RLA} component={Rla} />
      <Redirect to={routes.HOMEPAGE} />
    </Switch>
  );
};

export default RouterWrapper;
