import React, { ReactNode } from "react";
import classes from "./styles.module.scss";

type HeaderProps = {
  title: string;
  sideComponent: ReactNode;
};

const Header = ({ title, sideComponent }: HeaderProps): JSX.Element => {
  return (
    <header className={classes.Header}>
      <h2>{title}</h2>
      {sideComponent}
    </header>
  );
};

export default Header;
