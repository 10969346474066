import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../../shared/helpers/utilities";
import { PaginationType, RrfaType } from "../../shared/types";

type ActionType = {
  type: string;
  data: RrfaType[];
  error: string;
  page: number;
  serverPagination: PaginationType;
};

const INITIAL_STATE = {
  data: [],
  isFetching: false,
  didInvalidate: false,
  error: null,
  currentPage: 1,
  serverPagination: null,
  isLastPage: false,
};

const fetchRrfaStart = (state = INITIAL_STATE, action: ActionType) => {
  return updateObject(state, {
    data: action.page > 1 ? state.data : [],
    isFetching: true,
    error: null,
    didInvalidate: false,
    currentPage: state.currentPage,
    serverPagination: state.serverPagination,
  });
};

const fetchRrfaSuccess = (state = INITIAL_STATE, action: ActionType) => {
  const updatedData: RrfaType[] =
    action.data.length > 0
      ? (state.data as RrfaType[]).concat(action.data)
      : state.data;

  return updateObject(state, {
    data: updatedData,
    isFetching: false,
    currentPage: action.page,
    serverPagination: action.serverPagination,
    isLastPage: action.serverPagination.TotalPages === action.page,
  });
};

const fetchRrfaFail = (state = INITIAL_STATE, action: ActionType) => {
  return updateObject(state, {
    error: action.error,
    isFetching: false,
    didInvalidate: true,
  });
};

const resetData = (state = INITIAL_STATE) => {
  return updateObject(state, {
    data: [],
    isFetching: false,
    error: null,
    didInvalidate: false,
    currentPage: 1,
    serverPagination: null,
  });
};

const rrfaReducer = (state = INITIAL_STATE, action: ActionType) => {
  switch (action.type) {
    case actionTypes.FETCH_RRFA_START:
      return fetchRrfaStart(state, action);
    case actionTypes.FETCH_RRFA_SUCCESS:
      return fetchRrfaSuccess(state, action);
    case actionTypes.FETCH_RRFA_FAIL:
      return fetchRrfaFail(state, action);

    case actionTypes.RESET_RRFA_DATA:
      return resetData(state);

    default:
      return state;
  }
};

export default rrfaReducer;
