import React from "react";
import { Link } from "react-router-dom";
import classes from "./styles.module.scss";
import * as routes from "../../shared/routes";
import SvgArrowLeft from "../ArrowLeftIcon";

type BackButtonProps = {
  text: string;
};

const BackButton = ({ text }: BackButtonProps) => {
  return (
    <Link className={classes.BackButton} to={routes.HOMEPAGE}>
      <SvgArrowLeft width={10} height={14} />
      <span>{text}</span>
    </Link>
  );
};

export default BackButton;
