import React from "react";
import { RlaType, RrfaType } from "../../../shared/types";
import classes from "./styles.module.scss";
import TableRow from "../TableRow";

type TableBodyProps = {
  rrfaRows: RrfaType[];
  rlaRows: RlaType[];
};

const TableBody = ({
  rrfaRows = [],
  rlaRows = [],
}: TableBodyProps): JSX.Element => {
  return (
    <tbody className={classes.TableBody}>
      {rrfaRows.map((row: RrfaType, rowIx: number) => {
        return <TableRow key={rowIx} rrfaRow={row} rlaRow={null} />;
      })}
      {rlaRows.map((row: RlaType, rowIx: number) => {
        return <TableRow key={rowIx} rrfaRow={null} rlaRow={row} />;
      })}
    </tbody>
  );
};

export default TableBody;
