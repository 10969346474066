import React, { ReactNode } from "react";
import classes from "./styles.module.scss";
import { RlaType } from "../../shared/types";
import Loader from "../Loader";
import Table from "../Table";
import { RlaColumns } from "../../shared/helpers/columns";
import { dataTypes } from "../../shared/enums";

type RlaListProps = {
  items: RlaType[];
  totalCount: number;
  isLoading: boolean;
  searchComponent: ReactNode;
  keyword?: string;
  onReset(): void;
};

const RlaList = ({
  items = [],
  isLoading = false,
  totalCount,
  searchComponent,
  keyword,
  onReset,
}: RlaListProps) => {
  return (
    <section className={classes.RlaList}>
      <div className={classes["RlaList-items"]}>
        <Table
          searchComponent={searchComponent}
          title="Registro dei Laboratori di Analisi"
          columns={RlaColumns}
          rlaItems={items}
          rrfaItems={[]}
          dataType={dataTypes.rla}
          totalCount={totalCount}
          keyword={keyword}
          onReset={onReset}
          isLoading={isLoading}
        />
      </div>
      {isLoading && <Loader />}
    </section>
  );
};

export default RlaList;
