import React, { ReactNode } from "react";
import { ColumnType, RlaType, RrfaType } from "../../shared/types";
import Header from "../../containers/Header";
import TableHead from "./TableHead";
import TableBody from "./TableBody";
import classes from "./styles.module.scss";
import NoData from "../NoData";
import { dataTypes } from "../../shared/enums";
import BackButton from "../BackButton";

type TableProps = {
  title: string;
  columns: ColumnType[];
  rrfaItems: RrfaType[];
  rlaItems: RlaType[];
  totalCount: number;
  searchComponent: ReactNode;
  keyword?: string;
  onReset(): void;
  isLoading: boolean;
  dataType: dataTypes;
};

const Table = ({
  title,
  columns,
  rrfaItems = [],
  rlaItems = [],
  totalCount,
  searchComponent,
  keyword,
  onReset,
  isLoading = false,
  dataType,
}: TableProps): JSX.Element => {
  return (
    <section className={classes.Table}>
      <BackButton text="Homepage" />
      <Header
        title={`${title}${totalCount ? ` (${totalCount})` : ""}`}
        sideComponent={searchComponent}
      />
      {rrfaItems.length > 0 || rlaItems.length > 0 ? (
        <table className={classes["Table-container"]}>
          <TableHead columns={columns} />
          <TableBody
            rrfaRows={dataType === dataTypes.rrfa ? rrfaItems : []}
            rlaRows={dataType === dataTypes.rla ? rlaItems : []}
          />
        </table>
      ) : (
        keyword &&
        !isLoading && (
          <NoData
            onReset={onReset}
            message={`Nessun record trovato per la parola chiave "${keyword}"`}
          />
        )
      )}
    </section>
  );
};

export default Table;
