import React from "react";
import classes from "./styles.module.scss";

type NoDataProps = {
  message: string;
  onReset(): void;
};

const NoData = ({ message, onReset }: NoDataProps): JSX.Element => {
  return (
    <section className={classes.NoData}>
      <div className={classes["NoData-content"]}>
        <span>{message}</span>
        <button onClick={onReset}>Visualizza tutti</button>
      </div>
    </section>
  );
};

export default NoData;
