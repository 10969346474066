import request from "../../shared/lib/request";

const getData = (keyword: string, page: number = 1) => {
  let qs = `pagina=${page}${keyword ? `&stringaRicerca=${keyword}` : ""}`;

  const endpoint = `/public/rrfa?${qs}`;

  return request({
    url: endpoint,
    method: "GET",
  });
};

const RrfaService = {
  getData,
};

export default RrfaService;
