import React, { ReactNode } from "react";
import classes from "./styles.module.scss";
import { RrfaType } from "../../shared/types";
import Loader from "../Loader";
import Table from "../Table";
import { RrfaColumns } from "../../shared/helpers/columns";
import { dataTypes } from "../../shared/enums";

type RrfaListProps = {
  items: RrfaType[];
  totalCount: number;
  isLoading: boolean;
  searchComponent: ReactNode;
  keyword?: string;
  onReset(): void;
};

const RrfaList = ({
  items = [],
  isLoading = false,
  totalCount,
  searchComponent,
  keyword,
  onReset,
}: RrfaListProps) => {
  return (
    <section className={classes.RrfaList}>
      <div className={classes["RrfaList-items"]}>
        <Table
          searchComponent={searchComponent}
          title="Registro Regionale dei Formatori Alimentaristi"
          columns={RrfaColumns}
          rrfaItems={items}
          rlaItems={[]}
          dataType={dataTypes.rrfa}
          totalCount={totalCount}
          keyword={keyword}
          onReset={onReset}
          isLoading={isLoading}
        />
      </div>
      {isLoading && <Loader />}
    </section>
  );
};

export default RrfaList;
