import React from "react";
import classes from "./styles.module.scss";

type DataInfoProps = {
  overallText?: string;
  link?: {
    url: string;
    title: string;
  };
  title: string;
  description?: string;
  isSospeso?: boolean;
};

const DataInfo = ({
  title,
  description,
  link,
  overallText,
  isSospeso,
}: DataInfoProps): JSX.Element => {
  return (
    <div className={classes.DataInfo}>
      {isSospeso && <p className={classes["DataInfo-sospeso"]}>SOSPESA</p>}
      {link ? (
        <a
          className={classes["DataInfo-link"]}
          href={link.url}
          rel="noreferrer"
          title={link.title || title}
          target="_blank"
        >
          {title}
        </a>
      ) : (
        <p className={classes["DataInfo-title"]}>{title}</p>
      )}
      {description && (
        <span className={classes["DataInfo-description"]}>{description}</span>
      )}
      {overallText && (
        <p className={classes["DataInfo-overall"]}>{overallText}</p>
      )}
    </div>
  );
};

export default DataInfo;
