import React from "react";
import classes from "./styles.module.scss";
import { ColumnType } from "../../../shared/types";

type TableHeadProps = {
  columns: ColumnType[];
};

const TableHead = ({ columns }: TableHeadProps): JSX.Element => {
  return (
    <thead className={classes.TableHead}>
      <tr>
        {columns.map((col, ix: number) => {
          const classNames = [
            classes["TableHead-column"],
            col.width ? classes[`TableHead-column--${col.width}`] : "",
          ].join(" ");

          return (
            <th key={ix} className={classNames}>
              {col.name}
            </th>
          );
        })}
      </tr>
    </thead>
  );
};

export default TableHead;
