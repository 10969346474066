import React from "react";
import classes from "./styles.module.scss";

const Loader = () => {
  return (
    <div className={classes.Loader}>
      <span />
      <span />
      <span />
      <span />
      <span />
    </div>
  );
};

export default Loader;
