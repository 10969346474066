import React, { ChangeEvent, useCallback } from "react";
import classes from "./styles.module.scss";
import { DebounceInput } from "react-debounce-input";
import SvgSearch from "../SearchIcon";

const DEBOUNCE_TIMEOUT = 400;

type SearchFieldProps = {
  onSearch(value: string): void;
  debounceTimeout?: number;
  placeholder: string;
  value: string;
};

const SearchField = ({
  onSearch,
  debounceTimeout = DEBOUNCE_TIMEOUT,
  placeholder,
  value = "",
}: SearchFieldProps) => {
  const onHandleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      onSearch(e.target.value);
    },
    [onSearch]
  );

  return (
    <section className={classes.SearchField}>
      <DebounceInput
        value={value}
        placeholder={placeholder}
        type="text"
        minLength={3}
        debounceTimeout={debounceTimeout}
        onChange={onHandleChange}
      />
      <SvgSearch width={18} height={18} color="#9ea1a2" />
    </section>
  );
};

export default SearchField;
