import React from "react";
import { Link } from "react-router-dom";
import classes from "./styles.module.scss";

type NavLinkProps = {
  to: string;
  children: string;
  isSecondary?: boolean;
};

const NavLink = ({ to, children, isSecondary = false }: NavLinkProps) => {
  return (
    <Link
      className={[
        classes.NavLink,
        isSecondary ? classes["NavLink--secondary"] : "",
      ].join(" ")}
      to={to}
    >
      {children}
    </Link>
  );
};

export default NavLink;
