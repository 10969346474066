import React, { useCallback, useEffect, useState } from "react";
import classes from "./styles.module.scss";
import { connect } from "react-redux";
import * as actions from "../../store/actions";
import { PaginationType, RrfaType } from "../../shared/types";
import SearchField from "../../components/SearchField";
import RrfaList from "../../components/RrfaList";

const SCROLL_OFFSET = 50;

type RrfaProps = {
  data: RrfaType[];
  waitingForData: boolean;
  error: string;
  onGetData(keyword: string, page: number): RrfaType[];
  onResetData(): void;
  currentPage: number;
  isLastPage: boolean;
  serverPagination: PaginationType;
};

const Rrfa = ({
  data = [],
  waitingForData,
  error,
  onGetData,
  onResetData,
  currentPage = 1,
  isLastPage = false,
  serverPagination,
}: RrfaProps) => {
  const [keyword, setKeyword] = useState("");

  // GET DATA FROM API and set current page
  const onGetListing = useCallback(
    (currentPage: number) => {
      onGetData(keyword, currentPage);
    },
    [onGetData, keyword]
  );

  const onHandleReset = useCallback(() => {
    setKeyword("");
  }, []);

  // FROM onSetKeyword: check for listing searching or resetting
  useEffect(() => {
    onGetListing(1);
  }, [keyword, onGetListing, onResetData]);

  // FROM SearchField: Keyword (event.target.value)
  const onSetNewKeyword = useCallback((keyword: string) => {
    setKeyword(keyword);
  }, []);

  useEffect(() => {
    if (error) alert(error);
  }, [error]);

  // Infinite scroll
  const handleScroll = useCallback(() => {
    const scrollTop =
      (document.documentElement && document.documentElement.scrollTop) ||
      document.body.scrollTop;
    const scrollHeight =
      (document.documentElement && document.documentElement.scrollHeight) ||
      document.body.scrollHeight;
    if (
      scrollTop + window.innerHeight + SCROLL_OFFSET >= scrollHeight &&
      !waitingForData &&
      !isLastPage
    ) {
      onGetListing(currentPage + 1);
    }
  }, [waitingForData, isLastPage, currentPage, onGetListing]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [handleScroll]);

  return (
    <main className={classes.Rrfa}>
      <div className={classes["Rrfa-content"]}>
        <RrfaList
          onReset={onHandleReset}
          searchComponent={
            <SearchField
              onSearch={onSetNewKeyword}
              value={keyword}
              placeholder="Cerca per nome, numero, città, decreto"
            />
          }
          totalCount={serverPagination ? serverPagination.TotalCount : 0}
          items={data}
          isLoading={waitingForData}
          keyword={keyword}
        />
      </div>
    </main>
  );
};

const mapStateToProps = (state: any) => {
  return {
    data: state.rrfaState.data,
    waitingForData: state.rrfaState.isFetching,
    error: state.rrfaState.error,
    currentPage: state.rrfaState.currentPage,
    isLastPage: state.rrfaState.isLastPage,
    serverPagination: state.rrfaState.serverPagination,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    onGetData: (keyword: string, page: number) =>
      dispatch(actions.fetchRrfa(keyword, page)),
    onResetData: () => dispatch(actions.resetRrfaData()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Rrfa);
