import * as actionTypes from "./actionTypes";
import RlaService from "../../services/api/rla";
import { PaginationType, ResponseDataType, RlaType } from "../../shared/types";

// READ
export const fetchRlaStart = (page: number = 1) => {
  return {
    type: actionTypes.FETCH_RLA_START,
    page,
  };
};

export const fetchRlaSuccess = (
  data: RlaType[],
  page: number = 1,
  serverPagination: PaginationType
) => {
  return {
    type: actionTypes.FETCH_RLA_SUCCESS,
    data,
    page,
    serverPagination,
  };
};

export const fetchRlaFail = (error: string) => {
  return {
    type: actionTypes.FETCH_RLA_FAIL,
    error,
  };
};

export const fetchRla = (keyword: string, page: number = 1) => {
  return (dispatch: any) => {
    dispatch(fetchRlaStart(page));

    RlaService.getData(keyword, page).then(
      (response: ResponseDataType) => {
        dispatch(fetchRlaSuccess(response.Data, page, response.Pagination));
      },
      (error: ResponseDataType) => {
        let errorMsg: string = "";
        if (error.HasErrors && error.hasOwnProperty("ErrorMessage")) {
          if (error.ErrorMessage) {
            errorMsg = error.ErrorMessage;
          } else {
            errorMsg = "Internal server error";
          }
        } else {
          errorMsg = "";
        }

        dispatch(fetchRlaFail(errorMsg));
      }
    );
  };
};

export const resetRlaData = () => {
  return {
    type: actionTypes.RESET_RLA_DATA,
  };
};
