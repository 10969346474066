import * as React from 'react';

function SvgArrowLeft(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8 12" {...props}>
      <path
        fill="currentColor"
        d="M7.41 1.41 2.83 6l4.58 4.59L6 12 0 6l6-6z"
        opacity={0.8}
      />
    </svg>
  );
}

export default SvgArrowLeft;
