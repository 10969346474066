import React from "react";
import classes from "./styles.module.scss";
import * as routes from "../../shared/routes";
import NavLink from "../../components/NavLink";

const Home = () => {
  return (
    <main className={classes.Home}>
      <section className={classes["Home-content"]}>
        <NavLink isSecondary={true} to={routes.RRFA}>
          Registro Regionale dei Formatori Alimentaristi
        </NavLink>
        <NavLink to={routes.RLA}>Registro dei Laboratori di Analisi</NavLink>
      </section>
    </main>
  );
};

export default Home;
