import React, { ReactNode } from "react";
import classes from "./styles.module.scss";

type TableColumnProps = {
  children: ReactNode;
  dataColumn?: string;
};

const TableColumn = ({
  children,
  dataColumn,
}: TableColumnProps): JSX.Element => {
  return (
    <td data-column={dataColumn} className={classes.TableColumn}>
      {children}
    </td>
  );
};

export default TableColumn;
