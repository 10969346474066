import axios, { AxiosRequestConfig } from "axios";

console.log("ENV", process.env);
const API_BASE = process.env.REACT_APP_DEMETRA_API_ENDPOINT;

/**
 * Create an Axios Clients with defaults
 */
// Default config options
const defaultOptions: AxiosRequestConfig = {
  baseURL: `${API_BASE}`,
};

// Create instance
let Client = axios.create();

interface IResponse {
  data: any;
  config: object;
  status: number;
  headers: object;
}

interface IError {
  message: any;
  config: any;
  response: IResponse;
  Error?: string;
}

/**
 * Request Wrapper with default success/error actions
 */
const request = (options: AxiosRequestConfig) => {
  const onSuccess = (response: IResponse) => {
    //console.log('Request Successful!', response);
    return response.data;
  };

  const onError = (error: IError) => {
    if (error && error.response.status === 500) {
      console.error("Request Failed:", error.config);
    }

    if (error && error.response) {
      // Request was made but server responded with something
      // other than 2xx
      if (error.response.status === 500) {
        console.error("Status:", error.response.status);
        console.error("Data:", error.response.data);
        console.error("Headers:", error.response.headers);
      }

      if (error.response.status === 401) {
        const data = error.response.data;

        if (
          data.hasOwnProperty("errors") &&
          data.errors.length &&
          data.errors[0].message.toLowerCase() !== "unauthorized" &&
          data.errors[0].message.toLowerCase() !== "entity not found"
        ) {
          window.location.href = "/";
        }
      }
    } else {
      // Something else happened while setting up the request
      // triggered the error
      console.error(
        "Error Message:",
        "Maybe a problem with Server or your network is off."
      );
    }

    return Promise.reject(
      error
        ? error.response || error.message
        : "Maybe a problem with Server or your network is off."
    );
  };

  const config = {
    ...defaultOptions,
    ...options,
  };

  return Client(config).then(onSuccess).catch(onError);
};

export default request;
