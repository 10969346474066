// RRFA
export const FETCH_RRFA_START = "FETCH_RRFA_START";
export const FETCH_RRFA_SUCCESS = "FETCH_RRFA_SUCCESS";
export const FETCH_RRFA_FAIL = "FETCH_RRFA_FAIL";

export const RESET_RRFA_DATA = "RESET_RRFA_DATA";

// RLA
export const FETCH_RLA_START = "FETCH_RLA_START";
export const FETCH_RLA_SUCCESS = "FETCH_RLA_SUCCESS";
export const FETCH_RLA_FAIL = "FETCH_RLA_FAIL";

export const RESET_RLA_DATA = "RESET_RLA_DATA";
