import * as React from 'react';

function SvgSearch(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 14" {...props}>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M5.766 1.647a4.123 4.123 0 0 0-4.119 4.118 4.124 4.124 0 0 0 4.12 4.12 4.124 4.124 0 0 0 4.118-4.12 4.123 4.123 0 0 0-4.119-4.118M13.196 14a.797.797 0 0 1-.57-.236l-3.408-3.408a5.73 5.73 0 0 1-3.452 1.175A5.773 5.773 0 0 1 0 5.765 5.773 5.773 0 0 1 5.766 0a5.773 5.773 0 0 1 5.766 5.765 5.734 5.734 0 0 1-1.176 3.454l3.408 3.409a.79.79 0 0 1 .236.568.795.795 0 0 1-.236.57.794.794 0 0 1-.567.234"
      />
    </svg>
  );
}

export default SvgSearch;
