import { ColumnType } from "../types";

export const RrfaColumns: ColumnType[] = [
    {
        name: "Ragione sociale",
    },
    {
        name: "Sede legale",
    },
    {
        name: "Direttore dei corsi",
    },
    {
        name: "Formazione a distanza Asinc.",
    },
    {
        name: "Formazione a distanza Sinc.",
    },
    {
        name: "Formazione residenziale",
    },
    {
        name: "Decreti",
        width: 170,
    },
    {
        name: "Sedi operative",
        width: 300,
    },
];

export const RlaColumns: ColumnType[] = [
    {
        name: "Ragione sociale",
    },
    {
        name: "Sede legale",
    },
    {
        name: "Numero di iscrizione",
        width: 120,
    },
    {
        name: "Decreti",
        width: 200,
    },
];
