import React from "react";
import classes from "./styles.module.scss";

const Overall = (): JSX.Element => {
  return (
    <section className={classes.Overall}>
      <strong>DEMETRA</strong>
      <span>è sviluppato da Coop.</span>
      <a href="https://www.teseopsc.it" target="_blank" rel="noreferrer">
        Teseo
      </a>
      , Bagheria (PA)
    </section>
  );
};

export default Overall;
