import React from "react";
import classes from "./styles.module.scss";
import { getAvailability } from "../../shared/helpers/utilities";

type StatusProps = {
  value: string;
};

const Status = ({ value }: StatusProps): JSX.Element => {
  return getAvailability(value) ? (
    <p className={[classes.Status, classes["Status--green"]].join(" ")}>
      Disponibile
    </p>
  ) : (
    <p className={[classes.Status, classes["Status--red"]].join(" ")}>NO</p>
  );
};

export default Status;
