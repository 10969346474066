import React from "react";
import Overall from "./containers/Overall";
import { BrowserRouter } from "react-router-dom";
import RouterWrapper from "./containers/RouterWrapper";

const App = () => {
  return (
    <BrowserRouter>
      <Overall />
      <RouterWrapper />
    </BrowserRouter>
  );
};

export default App;
