import * as actionTypes from "./actionTypes";
import RrfaService from "../../services/api/rrfa";
import { PaginationType, ResponseDataType, RrfaType } from "../../shared/types";

// READ
export const fetchRrfaStart = (page: number = 1) => {
  return {
    type: actionTypes.FETCH_RRFA_START,
    page,
  };
};

export const fetchRrfaSuccess = (
  data: RrfaType[],
  page: number = 1,
  serverPagination: PaginationType
) => {
  return {
    type: actionTypes.FETCH_RRFA_SUCCESS,
    data,
    page,
    serverPagination,
  };
};

export const fetchRrfaFail = (error: string) => {
  return {
    type: actionTypes.FETCH_RRFA_FAIL,
    error,
  };
};

export const fetchRrfa = (keyword: string, page: number = 1) => {
  return (dispatch: any) => {
    dispatch(fetchRrfaStart(page));

    RrfaService.getData(keyword, page).then(
      (response: ResponseDataType) => {
        dispatch(fetchRrfaSuccess(response.Data, page, response.Pagination));
      },
      (error: ResponseDataType) => {
        let errorMsg: string = "";
        if (error.HasErrors && error.hasOwnProperty("ErrorMessage")) {
          if (error.ErrorMessage) {
            errorMsg = error.ErrorMessage;
          } else {
            errorMsg = "Internal server error";
          }
        } else {
          errorMsg = "";
        }

        dispatch(fetchRrfaFail(errorMsg));
      }
    );
  };
};

export const resetRrfaData = () => {
  return {
    type: actionTypes.RESET_RRFA_DATA,
  };
};
